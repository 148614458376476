<template>
  <div class="item-box">
    <expert-info-small
      :info="qa"
      class="expert-box"
      :limit="limit"
    ></expert-info-small>
    <van-divider :style="{ borderColor: '#B3B3B3' }" />
    <question-box :info="qa" :leftBorder="!!qa.answer"></question-box>
    <answer-box
      v-if="qa.answer"
      :info="qa"
      :limit="limit"
      class="answer-box"
    ></answer-box>
  </div>
</template>

<script>
import { Divider } from "vant";
import ExpertInfoSmall from "./ExpertInfoSmall.vue";
import QuestionBox from "./QuestionBox";
import AnswerBox from "./AnswerBox.vue";
export default {
  components: {
    QuestionBox,
    ExpertInfoSmall,
    AnswerBox,
    [Divider.name]: Divider,
  },
  props: {
    qa: {
      type: Object,
    },
    limit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showAnswer: false,
    };
  },
};
</script>

<style scoped>
.item-box {
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-left: 16px;
  margin-bottom: 12px;
  position: relative;
}

.center-box >>> .van-divider {
  margin: 12px 0 8px 0;
}

.center-box div {
  display: flex;
}

.bottom-box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.expert-box {
  /* margin-left: -40px; */
  position: relative;
  left: -40px;
}

.answer-box {
  margin-top: 12px;
}
</style>