<template>
  <div class="expert-box">
    <img class="avatar" :src="info.avatarSubUrl" />
    <div style="flex: 1">
      <div class="title">
        {{ info.name }}<span>{{ info.title }}</span>
      </div>
      <div class="top-center__box">
        <div class="top-sub__title">专家介绍</div>
        <div :class="{ 'top-sub__content': true, limit: limit }">
          {{ info.intro }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "limit"],
};
</script>

<style scoped>
.expert-box {
  display: flex;
}

.avatar {
  height: 60px;
  border-radius: 50%;
  margin-right: 12px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  color: #333333;
  align-items: flex-end;
}

.title span {
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  margin-left: 8px;
}

.top-center__box {
  margin-top: 4px;
  background: #f3f7fa;
  border-radius: 4px;
  font-size: 12px;
  line-height: 17px;
  color: #999999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.top-sub__title {
  padding: 2px 8px 2px 4px;
  background: #ffc83e;
  border-radius: 4px 0px 12px 0px;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
  display: flex;
  align-items: center;
}

.top-sub__content {
  margin: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.limit {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>