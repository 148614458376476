<template>
  <div :class="{'quiz-box': true, 'left-border': leftBorder}">
    <div class="quiz-title">
      <div>问题</div>
      <div>{{ info.questionDateTime }}</div>
    </div>
    <div class="quiz-content">
      {{ info.question }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "leftBorder"]
};
</script>

<style scoped>
.quiz-box {
  font-size: 14px;
  color: #999999;
}

.left-border {
  border-left: 3px solid #cccc;
  padding-left: 8px;
}

.quiz-title {
  display: flex;
  justify-content: space-between;
}

.quiz-title span {
  font-size: 12px;
}

.quiz-content {
  margin-top: 6px;
}
</style>