<template>
  <div class="answer-box">
    <div class="answer-title">
      <div>回答：</div>
      <span>{{ info.answerDateTime }}</span>
    </div>
    <div :class="{'answer-content': true, 'limit': limit }">
      {{ info.answer }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["info", "limit"],
};
</script>

<style scoped>
.limit {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.answer-box {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;
}

.answer-title {
  display: flex;
  justify-content: space-between;
}

.answer-title div {
  font-weight: 800;
}

.answer-title span {
  font-size: 12px;
}

.answer-content {
  margin-top: 6px;
  color: #4d4d4d;
}
</style>